import { useTheme } from "styled-components";

import { ColorPalette } from "../../styles/palette";

export const SwapArrows = ({
  color,
  size,
}: {
  color?: keyof ColorPalette;
  size?: string;
}) => {
  const { palette, font } = useTheme();

  return (
    <svg
      width={size || font.size.base}
      viewBox="0 0 373 315"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M197.001 100.913L168.012 2.57792C167.246 6.74798e-05 162.212 6.74798e-05 159.44 2.57792L53.395 100.913C50.878 103.248 52.1183 106.008 55.6932 106.008H75.7689C78.4197 106.008 79.9518 107.577 79.1979 109.522L0.196382 311.106C-0.569678 313.051 0.974603 314.62 3.62542 314.62H79.3195C81.9703 314.62 84.7306 313.051 85.4966 311.106L164.474 109.522C165.24 107.577 168 106.008 170.651 106.008H190.727C194.302 106.008 197.694 103.26 197.013 100.913H197.001Z"
        fill={palette[color || "accent"]}
      />
      <path
        d="M175.563 214.339L204.552 312.674C205.318 315.252 210.352 315.252 213.125 312.674L319.169 214.339C321.687 212.004 320.446 209.244 316.871 209.244H296.796C294.145 209.244 292.613 207.676 293.367 205.73L372.344 4.14649C373.11 2.20094 371.566 0.632324 368.915 0.632324H293.221C290.57 0.632324 287.81 2.20094 287.044 4.14649L208.066 205.73C207.3 207.676 204.54 209.244 201.889 209.244H181.814C178.239 209.244 174.846 211.992 175.527 214.339H175.563Z"
        fill={palette[color || "accent"]}
      />
    </svg>
  );
};
